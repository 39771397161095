@import "../utilities/_breakpoints.scss";

.canvas {
  padding: 3rem 4rem;
  background-color: var(--canvas-bg-color);

  @include respondTo(sm) {
    padding: 1rem 2rem;
  }

  p {
    line-height: 1.5;
    font-size: 1.25rem;
    @include respondTo(sm) {
      font-size: 1rem;
    }
  }

  &__title {
    font-size: 1.875rem;
    color: var(--canvas-title-color);
    margin-bottom: 0.4rem;
  }

  &__metaData {
    color: var(--canvas-subtitle-color);
    font-size: 1.25rem;
    font-weight: bold;
  }
}
