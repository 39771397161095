@import "../utilities/_breakpoints.scss";

.modal {
  width: 600px;
  background-color: var(--bg-color);
  position: absolute;
  top: 20%;
  left: calc(50% - 300px);
  z-index: 1;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  overflow: hidden;
  padding-bottom: 1rem;
  transform-style: preserve-3d;
  transform-origin: 0% 50%;
  opacity: 0;
  animation: slideDown 0.5s forwards ease-in-out;

  @include respondTo(sm) {
    width: 95%;
    left: 2.5%;
  }

  &__title {
    color: var(--white);
    margin: 0;
    padding: 1.5rem;
    background-color: var(--primary-color);
  }
  p {
    line-height: 1.5;
    padding: 0 2rem;
  }

  &__close {
    position: absolute;
    top: 1rem;
    right: 1.4rem;
    color: var(--white);
    font-size: 2rem;
    cursor: pointer;
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-100px);
  }
  50% {
    animation-timing-function: ease-out;
    opacity: 0.5;
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: backdropAnimation 0.25s forwards;
  cursor: url("../img/close-icon.svg"), auto;
}

@keyframes backdropAnimation {
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
