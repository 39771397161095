@import "../utilities/_breakpoints.scss";

.toolbar {
  padding: 1rem;
  border-bottom: 1px solid var(--toolbar-border-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: var(--toolbar-bg-color);

  @include respondTo(sm) {
    justify-content: space-around;
  }

  &__filters {
    @include respondTo(sm) {
      display: none;
    }
  }

  &__actions {
    @include respondTo(sm) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
