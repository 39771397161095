.button {
  border: 0;
  padding: 0.75rem 1rem;
  margin-right: 1rem;
  border-radius: 1.5rem;
  color: var(--white);
  background-color: var(--primary-color);
  font-weight: bold;
  text-decoration: none;
  font-size: 1rem;
  font-weight: normal;

  &.primary {
    background-color: var(--secondary-color-light);
  }
}
