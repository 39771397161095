@import "../utilities/_breakpoints.scss";

.sidebar {
  background-color: var(--primary-color);
  padding: 2rem;
  color: var(--sidebar-font-color);
  display: flex;
  flex-direction: column;
  align-content: space-around;

  a,
  button {
    color: var(--sidebar-font-color);
    border: 0;
    border-bottom: 2px solid #86dcaa;
    text-decoration: none;
    background: none;
    font-size: 1rem;
    padding: 0;
    font-weight: normal;
    cursor: pointer;
  }

  &__logo {
    margin: 0 auto;
    display: flex;
    align-content: center;
    justify-content: center;
    background-color: rgba($color: #ffffff, $alpha: 0.1);
    border-radius: 100%;
    width: 220px;
    height: 220px;
  }

  &__title {
    font-family: "Berkshire Swash", cursive;
    text-align: center;
    font-weight: normal;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  &__actions {
    @include respondTo(sm) {
      display: none;
    }
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .toggleSwitch {
      min-width: initial;
      margin-left: 0.5rem;
      margin-right: 0;
    }
  }
  &__description {
    font-weight: 300;
    margin-top: 0;
    text-align: right;

    @include respondTo(sm) {
      text-align: center;
    }
  }
  &__credits {
    content-visibility: auto;
    @include respondTo(sm) {
      display: none;
    }
    margin-top: auto;
    line-height: 2;
    text-align: right;

    @include respondTo(sm) {
      text-align: center;
    }
  }
}
