@import url(https://fonts.googleapis.com/css?family=Berkshire+Swash|Quicksand:300,400,700&display=swap);
/* Document
 * ========================================================================== *//**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}/* Sections
 * ========================================================================== *//**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}/* Grouping content
 * ========================================================================== *//**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */
dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}/**
 * Add the correct display in IE.
 */
main {
  display: block;
}/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}/* Text-level semantics
 * ========================================================================== *//**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}/* Embedded content
 * ========================================================================== *//**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}/* Forms
 * ========================================================================== *//**
 * Remove the margin on controls in Safari.
 */
button,
input,
select {
  margin: 0;
}/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */
button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}/**
 * Correct the inability to style buttons in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}/**
 * Show the overflow in Edge 18- and IE.
 */
input {
  overflow: visible;
}/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none;
}/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */
textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
}/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}/**
 * Remove the additional :invalid styles in Firefox.
 */
:-moz-ui-invalid {
  box-shadow: none;
}/* Interactive
 * ========================================================================== *//*
 * Add the correct display in Edge 18- and IE.
 */
details {
  display: block;
}/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}
dialog:not([open]) {
  display: none;
}/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}/* Scripting
 * ========================================================================== *//**
 * Add the correct display in IE.
 */
template {
  display: none;
}/* User interaction
 * ========================================================================== */

.app{--primary-color: #2da379;--accent-color: #fff55f;--secondary-color: #008ff5;--secondary-color-light: #008ff5;--text-color: #7f8595;--light-grey: #ddd;--selection-color: #e3ffdd;--white: #fff;--bg-color: var(--white);--canvas-bg-color: var(--white);--toolbar-bg-color: var(--white);--canvas-title-color: var(--primary-color);--canvas-subtitle-color: #467162;--toolbar-border-color: var(--light-grey);--toggle-switch-bg-color: var(--light-grey);--toggle-switch-bg-color-active: var(--primary-color);--toggle-switch-bg-color-thumb-active: #fff;--toggle-switch-bg-color-thumb: var(--white);--sidebar-font-color: var(--white)}.app.dark{--primary-color: #07263e;--accent-color: #56ff63;--secondary-color: #008ff5;--secondary-color-light: #008ff5;--text-color: #9dc2dc;--light-grey: #052235;--selection-color: #5f9ac3;--canvas-bg-color: #0c2c44;--white: #fff;--bg-color: var(--canvas-bg-color);--toolbar-bg-color: #052235;--canvas-title-color: #56ff63;--canvas-subtitle-color: #5f9ac3;--toolbar-border-color: #143e5a;--toggle-switch-bg-color: #1e4a6d;--toggle-switch-bg-color-active: #56ff63;--toggle-switch-bg-color-thumb: #2b76a7;--toggle-switch-bg-color-thumb-active: #085f10;--sidebar-font-color: var(--text-color)}::selection{background-color:var(--selection-color);color:var(--primary-color)}a{color:var(--secondary-color)}body{font-family:"Quicksand", sans-serif;margin:0}.app{display:grid;color:var(--text-color);grid-template-columns:minmax(288px, 15%) auto;margin:auto;position:fixed;height:100%;width:100%}@media (max-width: 575.98px){.app{display:block;position:static;position:initial}}.app main{overflow-y:scroll}

.canvas{padding:3rem 4rem;background-color:var(--canvas-bg-color)}@media (max-width: 575.98px){.canvas{padding:1rem 2rem}}.canvas p{line-height:1.5;font-size:1.25rem}@media (max-width: 575.98px){.canvas p{font-size:1rem}}.canvas__title{font-size:1.875rem;color:var(--canvas-title-color);margin-bottom:0.4rem}.canvas__metaData{color:var(--canvas-subtitle-color);font-size:1.25rem;font-weight:bold}

.button{border:0;padding:0.75rem 1rem;margin-right:1rem;border-radius:1.5rem;color:var(--white);background-color:var(--primary-color);font-weight:bold;text-decoration:none;font-size:1rem;font-weight:normal}.button.primary{background-color:var(--secondary-color-light)}

.toggleSwitch{min-width:200px;line-height:1.6;display:inline-block;margin-right:4rem}.toggleSwitch input[type="checkbox"]{height:0;width:0;visibility:hidden;display:none}.toggleSwitch label{cursor:pointer;text-indent:55px;width:50px;height:26px;background-color:var(--toggle-switch-bg-color);display:block;border-radius:21.25px;position:relative;white-space:nowrap}.toggleSwitch label:after{content:"";position:absolute;top:1.25px;left:1.5px;width:22.5px;height:22.5px;background-color:var(--toggle-switch-bg-color-thumb);border-radius:22.5px;transition:0.3s}.toggleSwitch input:checked+label{background-color:var(--toggle-switch-bg-color-active)}.toggleSwitch input:checked+label::after{background-color:var(--toggle-switch-bg-color-thumb-active)}.toggleSwitch input:checked+label:after{left:calc(100% - 1.25px);-webkit-transform:translateX(-100%);transform:translateX(-100%)}.toggleSwitch label:active:after{width:32.5px}

.toolbar{padding:1rem;border-bottom:1px solid var(--toolbar-border-color);display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:space-between;justify-content:space-between;position:-webkit-sticky;position:sticky;top:0;background-color:var(--toolbar-bg-color)}@media (max-width: 575.98px){.toolbar{-webkit-justify-content:space-around;justify-content:space-around}}@media (max-width: 575.98px){.toolbar__filters{display:none}}@media (max-width: 575.98px){.toolbar__actions{width:100%;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:space-between;justify-content:space-between}}

.sidebar{background-color:var(--primary-color);padding:2rem;color:var(--sidebar-font-color);display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-content:space-around;align-content:space-around}.sidebar a,.sidebar button{color:var(--sidebar-font-color);border:0;border-bottom:2px solid #86dcaa;text-decoration:none;background:none;font-size:1rem;padding:0;font-weight:normal;cursor:pointer}.sidebar__logo{margin:0 auto;display:-webkit-flex;display:flex;-webkit-align-content:center;align-content:center;-webkit-justify-content:center;justify-content:center;background-color:rgba(255,255,255,0.1);border-radius:100%;width:220px;height:220px}.sidebar__title{font-family:"Berkshire Swash", cursive;text-align:center;font-weight:normal;margin-bottom:2rem;padding-bottom:2rem;border-bottom:1px solid rgba(255,255,255,0.2)}.sidebar__actions{margin-top:1rem;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center}@media (max-width: 575.98px){.sidebar__actions{display:none}}.sidebar__actions .toggleSwitch{min-width:0;min-width:initial;margin-left:0.5rem;margin-right:0}.sidebar__description{font-weight:300;margin-top:0;text-align:right}@media (max-width: 575.98px){.sidebar__description{text-align:center}}.sidebar__credits{content-visibility:auto;margin-top:auto;line-height:2;text-align:right}@media (max-width: 575.98px){.sidebar__credits{display:none}}@media (max-width: 575.98px){.sidebar__credits{text-align:center}}

.modal{width:600px;background-color:var(--bg-color);position:absolute;top:20%;left:calc(50% - 300px);z-index:1;box-shadow:0 0 15px rgba(0,0,0,0.2);border-radius:16px;overflow:hidden;padding-bottom:1rem;-webkit-transform-style:preserve-3d;transform-style:preserve-3d;-webkit-transform-origin:0% 50%;transform-origin:0% 50%;opacity:0;-webkit-animation:slideDown 0.5s forwards ease-in-out;animation:slideDown 0.5s forwards ease-in-out}@media (max-width: 575.98px){.modal{width:95%;left:2.5%}}.modal__title{color:var(--white);margin:0;padding:1.5rem;background-color:var(--primary-color)}.modal p{line-height:1.5;padding:0 2rem}.modal__close{position:absolute;top:1rem;right:1.4rem;color:var(--white);font-size:2rem;cursor:pointer}@-webkit-keyframes slideDown{0%{-webkit-transform:translateY(-100px);transform:translateY(-100px)}50%{-webkit-animation-timing-function:ease-out;animation-timing-function:ease-out;opacity:0.5;-webkit-transform:translateY(-50px);transform:translateY(-50px)}100%{-webkit-transform:translateY(0px);transform:translateY(0px);opacity:1}}@keyframes slideDown{0%{-webkit-transform:translateY(-100px);transform:translateY(-100px)}50%{-webkit-animation-timing-function:ease-out;animation-timing-function:ease-out;opacity:0.5;-webkit-transform:translateY(-50px);transform:translateY(-50px)}100%{-webkit-transform:translateY(0px);transform:translateY(0px);opacity:1}}.backdrop{background-color:rgba(0,0,0,0.5);position:fixed;top:0;left:0;width:100%;height:100%;opacity:0;-webkit-animation:backdropAnimation 0.25s forwards;animation:backdropAnimation 0.25s forwards;cursor:url(/static/media/close-icon.5042c72d.svg),auto}@-webkit-keyframes backdropAnimation{50%{opacity:0.5}100%{opacity:1}}@keyframes backdropAnimation{50%{opacity:0.5}100%{opacity:1}}

.license{padding:2rem 4rem;font-weight:300;border-top:1px solid var(--light-grey);font-size:1.1rem;line-height:1.5;background-color:var(--light-grey)}

.messageBar{background-color:var(--accent-color);color:var(--primary-color);position:fixed;top:0;left:0;width:100%;padding:1.5rem 0;font-size:1.5rem;text-align:center;visibility:hidden}.messageBar.is-active{-webkit-animation:messageBar-slide-down 1.5s forwards ease-in-out;animation:messageBar-slide-down 1.5s forwards ease-in-out;visibility:visible}@-webkit-keyframes messageBar-slide-down{0%{-webkit-transform:translateY(-40px);transform:translateY(-40px);opacity:0}25%{opacity:1;-webkit-transform:translateY(0);transform:translateY(0)}75%{opacity:1;-webkit-transform:translateY(0);transform:translateY(0)}100%{-webkit-transform:translateY(-40px);transform:translateY(-40px);opacity:0}}@keyframes messageBar-slide-down{0%{-webkit-transform:translateY(-40px);transform:translateY(-40px);opacity:0}25%{opacity:1;-webkit-transform:translateY(0);transform:translateY(0)}75%{opacity:1;-webkit-transform:translateY(0);transform:translateY(0)}100%{-webkit-transform:translateY(-40px);transform:translateY(-40px);opacity:0}}

