@import "utilities/_breakpoints.scss";
@import url("https://fonts.googleapis.com/css?family=Berkshire+Swash|Quicksand:300,400,700&display=swap");

.app {
  --primary-color: #2da379;
  --accent-color: #fff55f;
  --secondary-color: #008ff5;
  --secondary-color-light: #008ff5;
  --text-color: #7f8595;
  --light-grey: #ddd;
  --selection-color: #e3ffdd;
  --white: #fff;
  --bg-color: var(--white);
  --canvas-bg-color: var(--white);
  --toolbar-bg-color: var(--white);
  --canvas-title-color: var(--primary-color);
  --canvas-subtitle-color: #467162;
  --toolbar-border-color: var(--light-grey);
  --toggle-switch-bg-color: var(--light-grey);
  --toggle-switch-bg-color-active: var(--primary-color);
  --toggle-switch-bg-color-thumb-active: #fff;
  --toggle-switch-bg-color-thumb: var(--white);
  --sidebar-font-color: var(--white);
}

/* Dark mode */

.app.dark {
  --primary-color: #07263e;
  --accent-color: #56ff63;
  --secondary-color: #008ff5;
  --secondary-color-light: #008ff5;
  --text-color: #9dc2dc;
  --light-grey: #052235;
  --selection-color: #5f9ac3;
  --canvas-bg-color: #0c2c44;
  --white: #fff;
  --bg-color: var(--canvas-bg-color);
  --toolbar-bg-color: #052235;
  --canvas-title-color: #56ff63;
  --canvas-subtitle-color: #5f9ac3;
  --toolbar-border-color: #143e5a;
  --toggle-switch-bg-color: #1e4a6d;
  --toggle-switch-bg-color-active: #56ff63;
  --toggle-switch-bg-color-thumb: #2b76a7;
  --toggle-switch-bg-color-thumb-active: #085f10;
  --sidebar-font-color: var(--text-color);
}

::selection {
  background-color: var(--selection-color);
  color: var(--primary-color);
}

a {
  color: var(--secondary-color);
}
$small-screen: 640px;

body {
  font-family: "Quicksand", sans-serif;
  margin: 0;
}
.app {
  display: grid;
  color: var(--text-color);
  grid-template-columns: minmax(288px, 15%) auto;
  margin: auto;
  position: fixed;
  height: 100%;
  width: 100%;

  @include respondTo(sm) {
    display: block;
    position: initial;
  }

  main {
    overflow-y: scroll;
  }
}
